body {
	font-size: 18px;

	@media only screen and (max-width: 1024px) {
		font-size: 16px;
	}
}

.main-content {
	background: #F7F7F7;
}

.home-carousel .detail-text .header {
	font-family: Work Sans, sans-serif;
	font-size: clamp(36px, 4.16667vw, 80px);
	letter-spacing: clamp(-2.5px, -.13021vw, -1px);
	line-height: 1;
	margin: 0 0 .35em;
}

.breadcrumbs {
	z-index: 2;
	position: relative;
	margin: 0px;
	padding: 16px 0;
}

.breadcrumbs-section + .section-page_hero {
	margin-top: -65px;
	min-height: 500px;

	@media only screen and (max-width: 1024px) {
		min-height: 350px;
	}
}

.main-content-container {
	padding-top: 30px;
}

.main-content {
	.TitleBlock {
		margin-bottom: -20px;
	}

	.title {
		color: #162D43;
		font-size: clamp(30px, 2.5vw, 48px);
		font-weight: 500;
		letter-spacing: -0.035em;
		margin-bottom: 0.5em;
	}

	.introduction {
		color: #162D43;
		font-size: clamp(16px, 1.25vw, 24px);
		letter-spacing: -0.035em;
	}
}

.section-page_hero .text .hero-header {
	font-family: "Work Sans", sans-serif;
	font-weight: 500;
	font-size: clamp(36px, 4.47917vw, 86px);
	letter-spacing: clamp(-2px, -0.10417vw, -0.75px);
	margin: 0 0 0.25em;
	line-height: 1;
}

.titlewrapper {
	padding-top: clamp(40px, 4.16667vw, 40px);
	padding-bottom: 20px;
	margin-bottom: clamp(-40px, 4.16667vw, -80px);
}

.o-fit {
	padding: 0px !important;
	width: 100%;
	display: block;

	img {
		height: 100%;
		width: 100%;
	}
}

figure {
	margin: 0px !important;
	display: flex;
	justify-content: center;
}

.c-pathway-carousel__item-icon {
	display: flex;
	justify-content: center;
}

.btn.primary {
	font-size: clamp(16px, 1.25vw, 24px);
	text-transform: uppercase;
	font-weight: 700;
	font-family: "Work Sans", sans-serif;
	text-decoration: none !important;
	transition: all 0.3s;
	color: #FFFFFF !important;
	background: #F40826 url('/Content/images/blackCorner.png') no-repeat right center;
	background-size: contain;
	letter-spacing: clamp(-0.35px, -0.01823vw, -0.15px);
	margin-bottom: 0;
	line-height: 30px;
	padding-top: clamp(10px, 0.88542vw, 17px);
	padding-bottom: clamp(10px, 0.88542vw, 17px);
	padding-left: clamp(20px, 1.77083vw, 34px);
	padding-right: clamp(40px, 3.4375vw, 66px);
	position: relative;
	border: none;

	&:hover {
		background-color: #8d0c18;
		cursor: pointer;
	}

	&:after {
		content: '';
		width: clamp(20px, 1.77083vw, 34px);
		height: 100%;
		background: #000000;
		opacity: 0.27;
		position: absolute;
		right: 0;
		top: 0;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	}
}

.Introduction {
	padding-bottom: 0px !important;
	margin-bottom: clamp(-20px, 4.16667vw, -40px);

	.grid-x {
		justify-content: center;
	}
}

.c-pathway-carousel {
	header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.c-pathway-carousel__link {
			font-size: clamp(13px, 0.83333vw, 16px);
			font-weight: 600;
			text-transform: uppercase;
			text-decoration: none !important;
			color: #BF1329;
			font-family: "Work Sans", sans-serif;
		}
	}
}

.section-sidebar .main-column--content p:first-of-type {
	font-size: clamp(15px, 1.04167vw, 20px);
}

/*.section-sidebar .main-column--content h2 {
	color: #162D43;
	font-size: clamp(30px, 2.5vw, 48px);
	font-weight: 500;
	letter-spacing: -0.035em;
	margin-bottom: 0.5em;
}*/

.main-nav ul.lvl-1 li.is-dropdown-submenu-parent > a:after {
	background: url('/Content/images/download.svg') no-repeat center center;
}

.section-page_hero .bg {
	overflow: hidden;

	.c-hero__video {
		z-index: 5;
		position: absolute;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.c-form {
	max-width: 800px;
	padding-bottom: clamp(40px, 4.16667vw, 80px);

	.umbraco-forms-form input.text,
	.umbraco-forms-form textarea,
	.umbraco-forms-form select {
		max-width: 800px !important;
		width: 100% !important;
	}
}

.c-google-map__figure {
	height: 600px;
}
/*====--  Sidebar Controls  --====*/

.SidebarControls {
	padding-left: 30px;
	padding-top: 30px;

	@media only screen and (max-width: 1024px) {
		padding-left: 0px;
	}

	section, .section-blocks {
		padding: 0 0 clamp(45px, 3.90625vw, 75px) 0;
	}

	.cell {
		width: 100%;
	}

	h2 {
		font-family: "Roboto Condensed", sans-serif;
		font-size: clamp(21px, 1.45833vw, 28px) !important;
		font-weight: 500 !important;
		margin: 0 0 15px;
		color: #2F4357 !important;
	}

	.item.item-featured {
		flex-direction: column-reverse;
	}

	.c-pod {
		margin-bottom: 30px;
	}

	.widget--resources {
		.item {
			h4 {
				display: flex;

				&:before {
					content: '';
					width: clamp(24px, 1.66667vw, 32px);
					height: clamp(24px, 1.66667vw, 32px);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					opacity: 0.7;
					margin-right: 12px;
					background-image: url(/Content/images/resource-tax.png);
				}
			}

			a {
				font-size: clamp(14px, 0.83333vw, 16px);
			}
		}
	}
}
/*====----====*/

.section-subcategories .item .text-body .button-link {
	display: inline-block;
	vertical-align: middle;
	margin: 0 0 1rem 0;
	padding: 0.85em 1em;
	border: 1px solid transparent;
	border-radius: 0;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	-webkit-appearance: none;
	line-height: 1;
	text-align: center;
	cursor: pointer;
	font-size: clamp(16px, 1.25vw, 24px);
	text-transform: uppercase;
	font-weight: 700;
	font-family: "Work Sans", sans-serif;
	text-decoration: none !important;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	color: #FFFFFF !important;
	background: #F40826;
	letter-spacing: clamp(-0.35px, -0.01823vw, -0.15px);
	margin-bottom: 0;
	line-height: 30px;
	padding-top: clamp(10px, 0.88542vw, 17px);
	padding-bottom: clamp(10px, 0.88542vw, 17px);
	padding-left: clamp(20px, 1.77083vw, 34px);
	padding-right: clamp(40px, 3.4375vw, 66px);
	position: relative;
	border: none;

	&:hover {
		background-color: #8d0c18;
	}

	&:after {
		content: "";
		width: clamp(20px, 1.77083vw, 34px);
		height: 100%;
		background: #000000;
		opacity: 0.27;
		position: absolute;
		right: 0;
		top: 0;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	}
}

/*====----====*/

.c-cta {
	&.c-cta--dark {
		background: #2F4357;
	}

	.item.item-featured {
		min-height: 400px;
	}

	p {
		font-size: clamp(16px, 1.25vw, 20px);
	}
}
/*====----====*/

.section-sidebar {
	.section-cta2 {
		.container {
			padding-left: clamp(35px, 3.90625vw, 75px);
			padding-right: clamp(35px, 3.90625vw, 75px);
		}
	}
}

.main-content {
	.section-blocks:first-child {
		//padding-top: 0px !important;

		h2 {
			margin-top: 0px;
		}
	}
}

.footer-columns nav ul li.h5 {
	margin-top: 0px;
}

.footer-nav {
	padding-top: 10px;
}

.c-footer__primary-sponsors-image {
	margin-bottom: 30px;
	display: block;
}

.footer-address {
	font-family: "Roboto Condensed", sans-serif;
	font-size: clamp(16px, 1.04167vw, 20px);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	text-decoration: none;

	a {
		font-family: "Roboto Condensed", sans-serif;
		font-size: clamp(16px, 1.04167vw, 20px);
		color: rgba(255, 255, 255, 0.55);
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		text-decoration: none;

		&:hover {
			color: #FFFFFF;
		}
	}
}
/*====----====*/

p:empty {
	display: none;
}

.section-blocks,
.section-team {
	padding-bottom: clamp(20px, 4.16667vw, 40px);
	padding-top: clamp(20px, 4.16667vw, 40px);
	position: relative;
}

/*====----====*/

.grid-grid {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	grid-template-rows: repeat(4, auto);

	&:has(.large-3) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	&:has(.large-4) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&:has(.large-6) {
		grid-template-columns: 1fr 1fr;
	}

	&:has(.medium-3) {
		@media only screen and (max-width: 1024px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	&:has(.medium-4) {
		@media only screen and (max-width: 1024px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&:has(.medium-6) {
		@media only screen and (max-width: 1024px) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.HasSidebar & {
		&:has(.large-3) {
			@media only screen and (min-width: 1024px) {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}

	.cell {
		display: grid;
		grid-template-rows: subgrid;
		grid-row: span 5;
		column-gap: 0px;
		row-gap: 0px;

		.item {
			display: grid;
			grid-template-rows: subgrid;
			grid-row: span 5;

			.text {
				display: grid;
				grid-template-rows: subgrid;
				grid-row: span 5;

				> .text-body {
					display: grid;
					grid-template-rows: subgrid;
					grid-row: span 5;

					> div {
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}
